<template>
    <dashboard-layout>
        <v-row>
            <v-col>
                <h3>
                    Your gig list
                </h3>
                <p class="text-caption">Edit and view your gigs</p>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                Add new gigs by visiting a
                <router-link :to="{name: 'venues-index'}">venue</router-link>
                page and booking a new event
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-data-table
                    :loading="loading"
                    loading-text="Loading... Please wait"
                    :headers="headers"
                    :items="gigs"
                    :items-per-page="20"
                    class="elevation-1"
                    sort-by="starts_at"
                    sort-desc
                >
                    <template v-slot:item.title="{ item }">
                        <router-link :to="{name: 'artist.gigs.show', params: {id: item.id}}">
                            {{ item.title }}
                        </router-link>
                    </template>
                    <template v-slot:item.starts_at="{ item }">
                        {{$dayjs(item.starts_at).format('MMM DD, YYYY, h:mm A')}}
                    </template>
                    <template v-slot:item.ends_at="{ item }">
                        {{$dayjs(item.ends_at).format('MMM DD, YYYY, h:mm A')}}
                    </template>
                    <template v-slot:item.is_confirmed_by_venue="{ item }">
                        <v-icon v-if="item['is_confirmed_by_venue']">mdi-check</v-icon>
                        <v-icon v-else>mdi-close</v-icon>
                    </template>
                    <template v-slot:item.is_confirmed_by_artist="{ item }">
                        <v-icon v-if="item['is_confirmed_by_artist']">mdi-check</v-icon>
                        <v-icon v-else>mdi-close</v-icon>
                    </template>
                    <template v-slot:item.actions="{item}">
                        <div>
                            <v-btn v-if="showRejectBtn(item)" color="green" @click="confirm(item)"> confirm</v-btn>
                            <v-btn v-if="showRejectBtn(item)" color="red" @click="reject(item)"> reject</v-btn>
                            <router-link :to="{name: 'artist.gigs.edit', params: {id: item.id}}">
                                <v-btn color="blue" @click="reject(item)"> edit</v-btn>
                            </router-link>
                        </div>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </dashboard-layout>
</template>

<script>
import Gig from '@/models/Gig';
import Artist from "@/models/Artist";
import DashboardLayout from "@/layouts/DashboardLayout";

export default {
    name: "users-index",
    components: {DashboardLayout,},
    data: function () {
        return {
            loading: false,
            gigs: [],
            headers: [
                {
                    text: '# ID',
                    align: 'start',
                    sortable: false,
                    value: 'id',
                },
                {text: 'Title', value: 'title'},
                {text: 'Venue', value: 'venue.name'},
                {text: 'Confirmed by Venue', value: 'is_confirmed_by_venue'},
                {text: 'Confirmed by Artist', value: 'is_confirmed_by_artist'},
                {text: 'Starts at', value: 'starts_at'},
                {text: 'Ends at', value: 'ends_at'},
                {text: 'Actions', value: 'actions'},
            ],
        }
    },
    methods: {
        async confirm(gig) {
            gig.is_confirmed_by_artist = (await gig.confirm()).is_confirmed_by_artist;
        },
        async reject(gig) {
            gig.rejected_at = (await gig.reject()).rejected_at;
        },
        showRejectBtn(gig) {
            return !gig['is_confirmed_by_artist'] && !gig['rejected_at']
        },
    },
    async mounted() {
        this.loading = true

        const artist = await Artist.where('user_id', this.$auth.user().id).first();
        if (artist) {
            this.gigs = await Gig.where('artist_id', artist.id).include(['venue']).get()
        }

        this.loading = false
    },
}
</script>

<style scoped>

</style>
