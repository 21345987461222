var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('dashboard-layout',[_c('v-row',[_c('v-col',[_c('h3',[_vm._v(" Your gig list ")]),_c('p',{staticClass:"text-caption"},[_vm._v("Edit and view your gigs")])])],1),_c('v-row',[_c('v-col',[_vm._v(" Add new gigs by visiting a "),_c('router-link',{attrs:{"to":{name: 'venues-index'}}},[_vm._v("venue")]),_vm._v(" page and booking a new event ")],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading,"loading-text":"Loading... Please wait","headers":_vm.headers,"items":_vm.gigs,"items-per-page":20,"sort-by":"starts_at","sort-desc":""},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{name: 'artist.gigs.show', params: {id: item.id}}}},[_vm._v(" "+_vm._s(item.title)+" ")])]}},{key:"item.starts_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$dayjs(item.starts_at).format('MMM DD, YYYY, h:mm A'))+" ")]}},{key:"item.ends_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$dayjs(item.ends_at).format('MMM DD, YYYY, h:mm A'))+" ")]}},{key:"item.is_confirmed_by_venue",fn:function(ref){
var item = ref.item;
return [(item['is_confirmed_by_venue'])?_c('v-icon',[_vm._v("mdi-check")]):_c('v-icon',[_vm._v("mdi-close")])]}},{key:"item.is_confirmed_by_artist",fn:function(ref){
var item = ref.item;
return [(item['is_confirmed_by_artist'])?_c('v-icon',[_vm._v("mdi-check")]):_c('v-icon',[_vm._v("mdi-close")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',[(_vm.showRejectBtn(item))?_c('v-btn',{attrs:{"color":"green"},on:{"click":function($event){return _vm.confirm(item)}}},[_vm._v(" confirm")]):_vm._e(),(_vm.showRejectBtn(item))?_c('v-btn',{attrs:{"color":"red"},on:{"click":function($event){return _vm.reject(item)}}},[_vm._v(" reject")]):_vm._e(),_c('router-link',{attrs:{"to":{name: 'artist.gigs.edit', params: {id: item.id}}}},[_c('v-btn',{attrs:{"color":"blue"},on:{"click":function($event){return _vm.reject(item)}}},[_vm._v(" edit")])],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }